import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Logo from "../assets/images/steves-favicon-red.png";
import { fetchSearchSalesOrderListBySalesOrderId } from "../modules/LandingPage";
import { useLocation } from "react-router-dom";
import '../i18n'; // Ensure this import initializes i18n

const Currentreceipt = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);
  const [costCenter, setCostCenter] = useState("");

  useEffect(() => {
    const storedCostCenter = localStorage.getItem("costCenter");
    if (storedCostCenter) {
      setCostCenter(storedCostCenter);
    }
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('orderDetails');
    if (storedData) {
      setFetchedData(JSON.parse(storedData));
      localStorage.removeItem('orderDetails');
    }
  }, []);

  const salesOrderId = fetchedData?.res?.message?.sales_order?.name;

  useEffect(() => {
    const { email } = JSON.parse(localStorage.getItem("user"));

    if (salesOrderId) {
      fetchSearchSalesOrderListBySalesOrderId(email, 1, salesOrderId)
        .then((res) => {
          console.log("SearchDataVioAPI", res[0]);
          setData(res[0]);
        })
        .catch((error) => {
          console.error('Error fetching sales order data:', error);
        });
    }
  }, [salesOrderId]);

  const calculateTotalTax = (taxes) => {
    if (!Array.isArray(taxes)) {
      return 0;
    }
    return taxes.reduce((sum, tax) => sum + tax.tax_amount, 0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };
  // console.log("data of REciept",data);

  const calculateSubTotal = (items) => {
    if (!Array.isArray(items)) {
      return 0;
    }
    return items.reduce((sum, item) => sum + item.amount, 0);
  };

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const originalData = JSON.parse(queryParams.get("state"));
    if (originalData) {
      setData(originalData);
    }
  }, [location.search]);

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      {data && (
        <section className="wrapper_receipt">
          <section className="container_receipt">
            <main className="maindiv_receipt">
              <div className="LogoXelato_receipt">
                <img src={Logo} alt="XelatoLogo" className="logo_img" />
              </div>
              <div className="address_receipt">
                <div>
                  <h3 className="gelato_heading__receipt">
                    {t('Currentreceipt.store_name')}
                  </h3>
                </div>
                <div className="sub_address_receipt">
                  <p className="para_receipt">{costCenter}</p>
                  <p className="para_receipt">
                    {t('Currentreceipt.address')}
                  </p>
                </div>
              </div>
            </main>
            <hr className="hr" />
            <div className="middle_div">
              <div className="date_receipt">
                <p className="para_receipt">
                  {t('Currentreceipt.sales_invoice_no')} {data.sales}
                </p>
                <p className="para_receipt">
                  {`${data.transaction_date} ${data.transaction_time}`}
                </p>
                <p className="para_receipt">
                  {t('Currentreceipt.cashier')} {data.hub_manager}
                </p>
              </div>
              <hr className="hr" />
              <div className="date_receipt">
                <p className="para_receipt">
                  {t('Currentreceipt.customer_id')} {data.customer}
                </p>
                <p className="para_receipt">
                  {t('Currentreceipt.customer_name')} {data.customer_name}
                </p>
                <br />
                <p className="para_receipt date_reciept">{t('Currentreceipt.items')}</p>
              </div>
              <ol className="list_receipt" start="1">
                {data.items.map((res, index) => (
                  <li key={index}>
                    <div className="sublist__receipt">
                      <p className="para_receipt">
                        {res.item_name}
                      </p>
                      <p className="para_receipt">{res.amount.toFixed(2)}</p>
                    </div>
                  </li>
                ))}
              </ol>
              <hr className="hr" />
              <div className="subtotal_receipt">
                <p className="para_receipt">{t('sub_total')}</p>
                <p className="para_receipt">
                  {calculateSubTotal(data.items).toFixed(2)}
                </p>
              </div>
              {data.coupon_code && (
                <div className="subtotal_receipt">
                  <p className="para_receipt">
                    {t('Currentreceipt.coupon_code')}{data.coupon_code}
                  </p>
                  <p className="para_receipt">- {data.discount_amount.toFixed(2)}</p>
                </div>
              )}
              {data.loyalty_amount !== 0 && (
                <div className="subtotal_receipt">
                  <p className="para_receipt">
                    {t('Currentreceipt.loyalty_points')}({data.loyalty_points})
                  </p>
                  <p className="para_receipt">- {data.loyalty_amount.toFixed(2)}</p>
                </div>
              )}
              <ol className="list_receipt" start="1">
                {data.tax_detail.map((res, index) => (
                  <li key={index}>
                    <div className="subtotal_receipt">
                      <p className="para_receipt">
                        {`${res.account_head}(${res.description})`}
                      </p>
                      <p className="para_receipt">+ {res.tax_amount.toFixed(2)}</p>
                    </div>
                  </li>
                ))}
              </ol>
              <hr className="hr" />
              <div className="subtotal_receipt">
                <p className="para_receipt">{t('Currentreceipt.total')}</p>
                <p className="para_receipt">
                  {(data.grand_total - data.loyalty_amount).toFixed(2)}
                </p>
              </div>
            </div>
            <hr className="hr" />
            <div>
              <p className="para_receipt">{t('Currentreceipt.thank_you')}</p>
              <p className="para_receipt">STEVESMUSIC</p>
            </div>
            <hr className="hr" />
            <p className="area_receipt">{t('Currentreceipt.promotions_area')}</p>
          </section>
        </section>
      )}
    </>
  );
};

export default Currentreceipt;
