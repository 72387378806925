import React from "react";
import { NavLink } from "react-router-dom";
import Home from "../assets/images/icon-home.svg";
import Order from "../assets/images/icon-order.svg";
import Customer from "../assets/images/icon-customer.svg";
import Profile from "../assets/images/icon-profile.svg";
import Logout from "../assets/images/logout.png";
import { getCloseShiftdata } from "../modules/LandingPage";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(); // Use the translation hook

  const handleLogout = async () => {
    const a = await getCloseShiftdata();
    console.log("***************Logout****************************", a);
  };

  return (
    <footer className="footer">
      <nav>
        <ul className="footer-nav">
          <li>
            <NavLink to="/main" className="footer-nav-item" activeClassName="active" exact>
              <img src={Home} alt="Home" />
              <span className="footer-label">{t('Footer.home')}</span> {/* Use translation */}
            </NavLink>
          </li>
          <li>
            <NavLink to="/order" className="footer-nav-item" activeClassName="active">
              <img src={Order} alt="Order" />
              <span className="footer-label">{t('Footer.order')}</span> {/* Use translation */}
            </NavLink>
          </li>
          <li>
            <NavLink to="/customer" className="footer-nav-item" activeClassName="active">
              <img src={Customer} alt="Customer" />
              <span className="footer-label">{t('Footer.customer')}</span> {/* Use translation */}
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile" className="footer-nav-item" activeClassName="active">
              <img src={Profile} alt="Profile" />
              <span className="footer-label">{t('Footer.profile')}</span> {/* Use translation */}
            </NavLink>
          </li>
          <li>
            <NavLink to="/closeshift" className="footer-nav-item" activeClassName="active">
              <img src={Logout} alt="Logout" />
              <span className="footer-label" onClick={handleLogout}>{t('Footer.logout')}</span> {/* Use translation */}
            </NavLink>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
