import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../components/style.css';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    const { t } = useTranslation();




    const totalPages = Math.ceil(totalItems / itemsPerPage);



    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
    };

    return (
        <div className="pagination">
            <button
                className="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || totalItems === 0}
            >
                {t('pagination.previous')} {/* Use translation */}
            </button>
            <div className="page-number">{currentPage}</div>
            <button
                className="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalItems === 0}
            >
                {t('pagination.next')} {/* Use translation */}
            </button>
        </div>
    );
};

Pagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
