import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Tooltip, Select } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Layout from "./Layout";
import { login } from "../modules/LandingPage";
import { useTranslation } from "react-i18next";
import { CartContext } from "../common/CartContext";

const { Option } = Select;

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/main");
    }
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const data = await login(email, password);

      if (data.message && data.message.success_key === 1) {
        const user = {
          name: data.message.username,
          email: data.message.email,
          role: data.message.role,
          profileImage: data.message.profile_image,
        };
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("sid", data.message.sid);
        localStorage.setItem("api_key", data.message.api_key);
        localStorage.setItem("api_secret", data.message.api_secret);

        navigate("/location", { state: { loginResponse: data } });

        // Auto-clear sensitive data after usage
        localStorage.removeItem("sid");
        localStorage.removeItem("api_key");
        localStorage.removeItem("api_secret");
      } else {
        alert(t("login.loginFail"));
      }
    } catch (error) {
      console.error("Login error:", error);
      alert(t("login.loginError"));
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 3000);
  };

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <div className="login-page">
      <Layout showFooter={false} showDropdown={false}>
        <div className="login-screen">
          <form className="login-form" onSubmit={handleLogin}>
            {/* Email Input */}
            <div className="form-group">
              <Input
                id="email"
                type="text"
                placeholder={t("login.emailPlaceholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="ant-input"
                style={{ width: "100%" }}
              />
            </div>
            {/* Password Input */}
            <div className="form-group">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder={t("login.passwordPlaceholder")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                suffix={
                  <Tooltip title={showPassword ? t("login.hidePassword") : t("login.showPassword")}>
                    {showPassword ? (
                      <EyeInvisibleOutlined onClick={handleTogglePasswordVisibility} />
                    ) : (
                      <EyeOutlined onClick={handleTogglePasswordVisibility} />
                    )}
                  </Tooltip>
                }
                className="ant-input"
              />
            </div>
            {/* Language Dropdown */}
            <div className="form-group">
              <Select
                defaultValue="en"
                onChange={handleLanguageChange}
                className="ant-input"
                style={{ width: "100%" }}
              >
                <Option value="en">{t("language.english")}</Option>
                <Option value="fr">{t("language.french")}</Option>
                {/* Add more languages here */}
              </Select>
            </div>
            {/* Login Button */}
            <button type="submit" className="submit-btn">
              {t("login.login")}
            </button>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default LoginScreen;
