import React from "react";
import NoImage from "../assets/images/no-img.png";
import StockPopUp from "./StockPopUp";

const ProductCard = ({ product, onAddToCart }) => {
  const [showStockPopup, setShowStockPopup] = React.useState(false);

  const cleanedProduct = {
    ...product,
    is_sales_item: product["is_sales_item "] // normalize the key
  };

  const formatPrice = (price) => {
    return price ? price.toFixed(2) : "NA";
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const handleAddToCart = () => {
    if (cleanedProduct.stock_qty <= 0) {
      setShowStockPopup(true);
    } else {
      onAddToCart(cleanedProduct);
    }
  };
  return (
    <>
      {cleanedProduct.is_sales_item === 1 && (
        <div className="product-card">
          <div className="product-image">
            <span className="quantity-bubble">
              {cleanedProduct.stock_qty <= 0 ? "0" : cleanedProduct.stock_qty}
            </span>
            <img
              src={cleanedProduct.image ? cleanedProduct.image : NoImage}
              alt={cleanedProduct.name || "Unnamed Product"}
            />
          </div>
          <div className="product-details">
            <h4 className="product-name">
              {truncateText(cleanedProduct.name || "Unnamed Product", 40)}
            </h4>
            <div className="price-addbtn">
              <span className="product-price">
                ${formatPrice(cleanedProduct.product_price)}
              </span>
              <button className="add-button" onClick={handleAddToCart}>
                +Add
              </button>
            </div>
          </div>
          {showStockPopup && (
            <StockPopUp
              product_name={cleanedProduct.name}
              onClose={() => setShowStockPopup(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ProductCard;
