import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useOpenShift } from "./OpenShiftContext";
import { getClosingShift, getCloseShiftdata } from "../modules/LandingPage";
import { CartContext } from "../common/CartContext";
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const CloseShiftScreen = () => {
  const { t } = useTranslation();
  const { openShiftData } = useOpenShift();
  const navigate = useNavigate();
  const [cashBalance, setCashBalance] = useState("");
  const [digitalBalance, setDigitalBalance] = useState("0.00");
  const [shiftData, setShiftData] = useState(null);
  const [shiftClosed, setShiftClosed] = useState(false); // Track if shift is closed
  const { setCartItems } = useContext(CartContext);

  const localDataOpenShift = localStorage.getItem("openingShiftResponse");
  const parsedData = JSON.parse(localDataOpenShift);

  const posname = parsedData?.message?.pos_opening_shift?.name || "";

  useEffect(() => {
    const fetchData = async () => {
      if (!shiftClosed && localDataOpenShift) { // Fetch data only if shift is not closed
        try {
          const result = await getCloseShiftdata(posname);
          console.log("API Response:", result);
          setShiftData(result);
        } catch (error) {
          console.error("Failed to fetch close shift data:", error);
        }
      }
    };

    fetchData();
  }, [posname, shiftClosed]); // Add shiftClosed as a dependency

  useEffect(() => {
    const balanceDetails =
      openShiftData?.message?.pos_opening_shift?.balance_details || [];
    const cashDetail = balanceDetails.find(
      (detail) => detail.mode_of_payment === "Cash"
    );
    const digitalDetail = balanceDetails.find(
      (detail) => detail.mode_of_payment !== "Cash"
    );

    setCashBalance(cashDetail ? cashDetail.amount.toString() : "0.00");
    setDigitalBalance(digitalDetail ? digitalDetail.amount.toString() : "0.00");
  }, [openShiftData]);

  const handleLogout = async () => {
    try {
      if (!cashBalance || cashBalance === "0.00") {
        notification.info({
          message: t('CloseShiftScreen.cash_balance_required'),
          description: t('CloseShiftScreen.cash_balance_description'),
        });
        return;
      }

      const openingShiftResponseStr = localStorage.getItem(
        "openingShiftResponse"
      );

      if (!openingShiftResponseStr) {
        console.error(t('CloseShiftScreen.no_data_found'));
        return;
      }

      const openingShiftResponse = JSON.parse(openingShiftResponseStr);

      if (
        !openingShiftResponse ||
        !openingShiftResponse.message ||
        !openingShiftResponse.message.pos_opening_shift ||
        openingShiftResponse.message.pos_opening_shift.status !== "Open"
      ) {
        alert(t('CloseShiftScreen.error_closing_shift'));
        return;
      }

      const periodStartDate =
        openingShiftResponse.message.pos_opening_shift.period_start_date || "";
      const postingDate = new Date().toISOString();
      const periodEndDate = new Date().toISOString();

      const closingShiftData = {
        closing_shift: {
          period_start_date: formatDateString(periodStartDate),
          posting_date: formatDateString(postingDate),
          pos_profile: openingShiftResponse.message.pos_profile.name,
          pos_opening_shift: openingShiftResponse.message.pos_opening_shift.name,
          doctype: "POS Closing Shift",
          payment_reconciliation: [
            {
              mode_of_payment: "Cash",
              opening_amount: parseFloat(cashBalance) || 0.0,
              closing_amount: parseFloat(cashBalance) || 0.0,
              expected_amount: parseFloat(cashBalance) || 0.0,
              difference: 0.0,
            },
          ],
          period_end_date: formatDateString(periodEndDate),
        },
      };

      const response = await getClosingShift(closingShiftData);

      if (response) {
        console.log(t('CloseShiftScreen.logout_success'));
        setShiftClosed(true); // Mark shift as closed
        navigate("/closeshift");
        setTimeout(() => {
          navigate("/");
          setCartItems([]);
        }, 1000);
        localStorage.clear();
      } else {
        console.error(t('CloseShiftScreen.error_closing_shift'));
      }
    } catch (error) {
      console.error(t('CloseShiftScreen.error_closing_shift'), error);
      alert(t('CloseShiftScreen.error_closing_shift') + ": " + error.message);
    }
  };

  const formatDateString = (dateString) => {
    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date");
    }
    return dateObj.toISOString().slice(0, 23).replace("T", " ");
  };

  const total = shiftData && shiftData.message.Shift_Detail.length > 0
    ? shiftData.message.Shift_Detail[0].cash_collected +
    shiftData.message.Shift_Detail[0].return_order_amount
    : 0;

  return (
    <Layout>
      <div className="login-screen">
        <h1>{t('CloseShiftScreen.close_shift_header')}</h1>
        <form className="login-form">
          <div className="form-group">
            <input
              id="cash-balance"
              type="text"
              placeholder={t('CloseShiftScreen.enter_cash_balance')}
              value={cashBalance}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value) || value === "") {
                  setCashBalance(value);
                }
              }}
            />
            <span>
              {t('CloseShiftScreen.system_closing_cash_balance')} : ${total.toFixed(2)}
            </span>
          </div>
          <button
            type="button"
            onClick={handleLogout}
          >
            {t('CloseShiftScreen.close_shift_button')}
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default CloseShiftScreen;
