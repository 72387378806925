import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { fetchforCustomers, updateCustomer } from "../modules/LandingPage";
import SearchIcon from "../assets/images/icon-search.png";
import EditIcon from "../assets/images/icon-edit.png";
import { Button, Input, Modal, Spin, notification } from "antd";
import Pagination from "../components/pagination";
import { useTranslation } from "react-i18next";
const CustomerPage = () => {

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    name: "",
    mobile: "",
    email: "",
    pinCode: "",
    language: "",
  });
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [newEmailID, setNewEmailID] = useState("");
  const [errors, setErrors] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  // const customersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(6);
  const itemsPerPage = 6;
  const { t } = useTranslation();


  // Function to handle page changes



  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const customerData = await fetchforCustomers();
        setCustomers(customerData);
        setFilteredCustomers(customerData); // Initially set filteredCustomers to all customers
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadCustomers();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentCustomers = filteredCustomers.slice(startIndex, startIndex + itemsPerPage);

  const filteredCustomer = customers.filter((customer) =>
    customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.mobile_no.includes(searchTerm)
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^\d{10}$/;
    return re.test(String(mobile));
  };

  const handleUpdateCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setNewName(customer.customer_name);
    setNewMobile(customer.mobile_no);
    // console.log(customer.email_id);
    setNewEmailID(customer.email_id);
    setIsModalOpen(true);
  };

  const handleUpdateCustomer = async () => {
    let validationErrors = {};

    // Check if any detail has been edited
    const isDetailsChanged =
      newName !== selectedCustomer.customer_name ||
      newMobile !== selectedCustomer.mobile_no ||
      newEmailID !== selectedCustomer.email_id;

    // If no details are changed, simply return without validation
    if (!isDetailsChanged) {
      notification.info({
        message: t("NO Changes were made"),
        description: t("No changes were made to the customer"),
      });
      return;
    }

    // Validate mobile number format
    if (!validateMobile(newMobile)) {
      validationErrors.mobile = t("validation.mobile");
    }

    // Validate email only if it's entered (email is not mandatory)
    if (newEmailID && !validateEmail(newEmailID)) {
      validationErrors.email = t("validation.email");
    }

    // Check if mobile number already exists in other customer records
    const isMobileExists = customers.some(
      (customer) =>
        customer.mobile_no === newMobile && customer.name !== selectedCustomer.name
    );

    // Check if email already exists in other customer records (if email is provided)
    const isEmailExists = newEmailID
      ? customers.some(
        (customer) =>
          customer.email_id === newEmailID && customer.name !== selectedCustomer.name
      )
      : false;

    if (isMobileExists) {
      notification.error({
        message: t("notification.mobileExistsError"),
        description: t("notification.mobileExistsDescription"),
      });
      return;
    }

    if (isEmailExists) {
      notification.error({
        message: t("notification.emailExistsError"),
        description: t("notification.emailExistsDescription"),
      });
      return;
    }

    // If there are any validation errors, display them and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      notification.error({
        message: t("notification.validationError"),
        description: t("notification.validationDescription"),
      });
      return;
    }

    try {
      // Proceed with API call if validation passes
      const updatedCustomer = await updateCustomer({
        name: selectedCustomer.name,
        customer_name: newName,
        mobile_no: newMobile,
        email_id: newEmailID,
      });

      // Refetch the customer data after the update
      const customerData = await fetchforCustomers();
      setCustomers(customerData);
      setFilteredCustomers(customerData);

      setIsModalOpen(false);
      setErrors({});
      notification.success({
        message: t("notification.updateSuccess"),
        description: t("notification.customerUpdated"),
      });
    } catch (error) {
      console.error(t("error.updateCustomer"), error);
      notification.error({
        message: t("notification.updateError"),
        description: t("notification.customerUpdateFailed"),
      });
    }
  };




  const handleEmailChange = (e) => {
    setNewEmailID(e.target.value);
  };

  const handleFilterApply = () => {
    let filtered = customers.filter((customer) => {
      let matches = true;

      if (filters.name && !customer.customer_name.toLowerCase().includes(filters.name.toLowerCase())) {
        matches = false;
      }

      if (filters.mobile && customer.mobile_no !== filters.mobile) {
        matches = false;
      }

      if (filters.email && !customer.email_id.toLowerCase().includes(filters.email.toLowerCase())) {
        matches = false;
      }

      if (filters.pinCode && customer.pinCode !== filters.pinCode) {
        matches = false;
      }

      if (filters.language && customer.language.toLowerCase() !== filters.language.toLowerCase()) {
        matches = false;
      }

      return matches;
    });

    filtered.sort((a, b) => a.customer_name.localeCompare(b.customer_name));

    setFilteredCustomers(filtered);
    setCurrentPage(1);
    setFilterVisible(false);
  };


  const handleFilterClear = () => {
    setFilters({
      name: "",
      mobile: "",
      email: "",
      pinCode: "",
      language: "",
    });
    setFilteredCustomers(customers);
    setFilterVisible(false);
  };


  // Calculate the indexes for slicing the products array
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredCustomers.slice(indexOfFirstProduct, indexOfLastProduct);
  // Determine total number of pages
  const totalPages = Math.ceil(filteredCustomers.length / productsPerPage);

  console.log("Total Pages", totalPages);





  return (
    <Layout>
      <div className="main-cont customer-page">
        <div className="heading-cont">
          <h1>{t("customerPage.title")}</h1>
          <div className="filter-section">
            <button onClick={handleFilterClear} className="filter-btn">
              {t("customerPage.clearFilter")}
            </button>
            <button onClick={handleFilterClick} className="filter-btn">
              {t("customerPage.filter")}
            </button>
            {filterVisible && (
              <div className="filter-dropdown">
                <input
                  type="text"
                  name="name"
                  placeholder={t("customerPage.searchName")}
                  value={filters.name}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  name="mobile"
                  placeholder={t("customerPage.searchMobile")}
                  value={filters.mobile}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  name="email"
                  placeholder={t("customerPage.searchEmail")}
                  value={filters.email}
                  onChange={handleFilterChange}
                />

                <button className="apply-btn" onClick={handleFilterApply}>
                  {t("customerPage.apply")}
                </button>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="loading-spin">
            <Spin tip={t("loading")}></Spin>
          </div>
        ) : error ? (
          <p>{t("error.loadingCustomers")}:{error}</p>
        ) : (

          <ul className="customer-list">

            {currentProducts.map((customer, index) => (
              <li key={index}>
                <p>
                  <span className="cus-name">{customer.customer_name}</span>
                  <span className="cus-mobile">{customer.mobile_no}</span>
                </p>
                <p>
                  <span className="cus-email">{customer.email_id}</span>
                  <button
                    className="cus-edit"
                    onClick={() => handleUpdateCustomerClick(customer)}
                  >
                    <img src={EditIcon} alt="Edit" />
                  </button>
                </p>
              </li>
            ))}
            <div className="pagena">
              <Pagination
                totalItems={Math.ceil(filteredCustomers.length)}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </ul>

        )}

      </div>
      <Modal
        title={t("modal.updateCustomer")}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleUpdateCustomer}>
            Update
          </Button>,
        ]}
        className="edit-customer-modal"
      >
        <div className="edit-cus-row">
          <Input
            type="text"
            placeholder={t("modal.name")}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="edit-cus-row"
          />
        </div>
        <div className="edit-cus-row">
          <Input
            type="text"
            placeholder={t("modal.mobile")}
            value={newMobile}
            onChange={(e) => setNewMobile(e.target.value)}
            className="edit-cus-row"
          />
          {errors.mobile && <p style={{ color: 'red' }}>{errors.mobile}</p>}
        </div>
        {/* <div className="edit-cus-row"> */}
        <div >
          <Input
            type="email"
            placeholder={t("modal.email")}
            value={newEmailID}
            onChange={handleEmailChange}
            className="edit-cus-row_email"
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
      </Modal>
    </Layout>
  );
};


export default CustomerPage;
