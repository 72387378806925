import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import IconDelete from "../assets/images/Delete.png";
import IconRightArrow from "../assets/images/icon-rightArrow.png";
import Print from "../assets/images/print.svg";

const OrderBox = ({
  order,
  showPayNow,
  showDelete,
  showMoveToCart,
  onClick,
  onDelete,
  selectedOrder,
  indicator,
}) => {
  const { t } = useTranslation();

  const getReturnStatusText = (status, mode_of_payment) => {
    if (status === "Return") {
      return t("orderBox.returned");
    } else if (status === "Paid") {
      return t("Paid");
    } else if (mode_of_payment === "Credit") {
      return t("Credit");
    } else {
      return "";
    }
  };

  // console.log("Order Box ", order);

  const returnStatusText = getReturnStatusText(
    order.status,
    order.mode_of_payment
  );

  const boxClassName = `col-md-3 order-box ${
    order.return_order_status === "Fully" ? "returned" : ""
  } ${order.return_order_status === "Partially" ? "returned" : ""}`;

  const handleClickOrder = () => {
    onClick(order);
  };

  const handleDeleteOrder = (e) => {
    e.stopPropagation();
    onDelete(order.name);
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    const url = "/receipt";
    const state = { response: order };
    const encodedState = encodeURIComponent(JSON.stringify(state));
    window.open(`${url}?state=${encodedState}`, "_blank");
  };

  return (
    <>
      {indicator ? (
        <Card className={boxClassName} onClick={handleClickOrder}>
          <p>
            <span>
              {t("orderBox.id")}: {order.sales}
            </span>
            <span className="light-text">
              ${" "}
              {order.grand_total
                ? (order.grand_total - order.loyalty_amount).toFixed(2)
                : "0.00"}
            </span>
          </p>
          <p className="order-date">
            {order.transaction_date}, {order.transaction_time}
          </p>
          <p className="status-row">
            {indicator && order.customer_name && (
              <div className="printdiv">
                <div>
                  {order.contact_mobile}{" "}
                  <span className="light-text">| {order.customer_name}</span>
                </div>
                <img
                  src={Print}
                  onClick={handlePrint}
                  className="printbtn"
                  alt={t("orderBox.print")}
                />
              </div>
            )}
            <span>
              {showDelete && (
                <img
                  src={IconDelete}
                  alt={t("orderBox.delete")}
                  onClick={handleDeleteOrder}
                />
              )}
              {showMoveToCart && (
                <img src={IconRightArrow} alt={t("orderBox.moveToCart")} />
              )}
              {showPayNow && <a href="#">{t("orderBox.payNow")}</a>}
              {returnStatusText && (
                <p className="return-status">{returnStatusText}</p>
              )}
            </span>
          </p>
        </Card>
      ) : (
        <Card className={boxClassName} onClick={handleClickOrder}>
          <p>
            <span>
              {t("orderBox.id")}: {order.name}
            </span>
            <span className="light-text">
              $ {order.subtotal ? order.subtotal.toFixed(2) : "0.00"}
            </span>
          </p>
          <p className="order-date">{order.creation}</p>
          <p className="status-row">
            <div className="printdiv">
              <div>
                {order.customer.mobile_no}{" "}
                <span className="light-text">
                  | {order.customer.customer_name}
                </span>
              </div>
            </div>
            <span>
              {showDelete && (
                <img
                  src={IconDelete}
                  alt={t("orderBox.delete")}
                  onClick={handleDeleteOrder}
                />
              )}
              {showMoveToCart && (
                <img src={IconRightArrow} alt={t("orderBox.moveToCart")} />
              )}
            </span>
          </p>
        </Card>
      )}
    </>
  );
};

OrderBox.propTypes = {
  order: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    creation: PropTypes.string.isRequired,
    contact_mobile: PropTypes.string.isRequired,
    contact_name: PropTypes.string.isRequired,
    return_order_status: PropTypes.string,
  }).isRequired,
  showPayNow: PropTypes.bool,
  showDelete: PropTypes.bool,
  showMoveToCart: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OrderBox;
