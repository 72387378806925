
import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import Pagination from "./pagination";
import { useTranslation } from 'react-i18next';

const ProductCatalog = ({ categoryName, products, onAddToCart }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const { t } = useTranslation();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [categoryName]);


  const startIndex = (currentPage - 1) * productsPerPage;
  const currentCustomers = products.slice(startIndex, startIndex + productsPerPage);
  return (
    <div className="product-catalog">
      <h2>{categoryName}</h2>
      <div className="product-list">
        {currentCustomers.length > 0 ? (
          currentCustomers.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onAddToCart={onAddToCart}
            />
          ))
        ) : (
          <p>{t('productCatalog.noProducts')}</p>
        )}
      </div>
      <Pagination
        totalItems={products.length}
        itemsPerPage={productsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ProductCatalog;
