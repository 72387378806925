import React, { useEffect, useState } from "react";
import { Input, Button, notification } from "antd";
import CloseCalc from "../../src/assets/images/calc-close.png";
import { useTranslation } from "react-i18next";
import LoginPopup from "./LoginPopUp";

const CashPaymentPopup = ({ total, isVisible, onClose, handlePlaceOrder }) => {
  const { t } = useTranslation();
  const [cashReceived, setCashReceived] = useState("");

  useEffect(() => {
    if (!isVisible) {
      setCashReceived("");
    }
  }, [isVisible]);

  const handleCashReceivedChange = (event) => {
    setCashReceived(event.target.value);
  };

  const handleKeypadClick = (value) => {
    setCashReceived((prev) => prev + value);
  };

  // const handlePopupOK = () => {
  //   const received = cashReceived || total.toFixed(2); // Use total if cashReceived is empty

  //   if (parseFloat(received) < total) {
  //     notification.error({
  //       message: t("cashPaymentPopup.insufficientCash"),
  //       description: t("cashPaymentPopup.insufficientCashDescription"), // Optional description
  //       placement: "topRight",
  //     });
  //     return;
  //   }

  //   const balance = received - total.toFixed(2);
  //   localStorage.setItem(
  //     "cashTransaction",
  //     JSON.stringify({
  //       total: total.toFixed(2),
  //       cashReceived: parseFloat(received).toFixed(2),
  //       balance: balance.toFixed(2),
  //     })
  //   );
  //   handlePlaceOrder();
  //   onClose();
  // };
  const handlePopupOK = () => {
    const received = cashReceived.trim() === "" ? total.toFixed(2) : cashReceived;

    const receivedAmount = parseFloat(received);
    const totalAmount = parseFloat(total.toFixed(2));

    // Check if received amount is less than the total
    if (receivedAmount < totalAmount) {
      notification.error({
        message: t("cashPaymentPopup.insufficientCash"),
        description: t("cashPaymentPopup.insufficientCashDescription"),
        placement: "topRight",
      });
      return;
    }

    // Calculate balance
    const balance = receivedAmount - totalAmount;

    // Store the transaction data
    localStorage.setItem(
      "cashTransaction",
      JSON.stringify({
        total: totalAmount.toFixed(2),
        cashReceived: receivedAmount.toFixed(2),
        balance: balance.toFixed(2),
      })
    );

    handlePlaceOrder();
    onClose();
  };

  const handleKeypadClear = () => {
    setCashReceived("");
  };

  return (
    isVisible && (
      <div className="overlay">
        <div className="cash-popup-box">
          <div className="popup-content">
            <div className="popup-buttons">
              <Button onClick={onClose} className="close-calc">
                <img src={CloseCalc} alt={t('cashPaymentPopup.popupCloseAlt')} />
              </Button>
            </div>
            <div className="popup-main">
              <div className="popup-left-cont">
                <div className="popup-row">
                  <label>{t('cashPaymentPopup.grandTotal')}</label>
                  <Input value={total.toFixed(2)} readOnly />
                </div>
                <div className="popup-row">
                  <label>{t('cashPaymentPopup.cashReceived')}</label>
                  <Input
                    type="text"
                    value={cashReceived}
                    onChange={handleCashReceivedChange}
                  />
                </div>
                <div className="popup-row">
                  <label>{t('cashPaymentPopup.balance')}</label>
                  <Input
                    value={(cashReceived ? cashReceived - total : 0).toFixed(2)}
                    readOnly
                  />
                </div>
              </div>
              <div className="keypad">
                {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map(
                  (num) => (
                    <Button key={num} onClick={() => handleKeypadClick(num)}>
                      {num}
                    </Button>
                  )
                )}
                <Button onClick={handleKeypadClear} className="clearbtn">
                  {t('cashPaymentPopup.clear')}
                </Button>
                <Button onClick={handlePopupOK} className="ok-btn">
                  {t('cashPaymentPopup.ok')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CashPaymentPopup;
