import React, { useEffect, useState } from "react";
import { changePassword, fetchBasicInfo } from "../modules/LandingPage";
import { useOpenShift } from "./OpenShiftContext";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const BasicInfo = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { openShiftData } = useOpenShift();
  const { cashBalance, digitalBalance, selectedProfile } = openShiftData;
  const { t } = useTranslation(); // Import translation function

  const handleChangePassword = async () => {
    try {
      const response = await changePassword(email, password);
      if (response.message.success_key === 1) {
        setMessage(t('basicInfo.changePasswordSuccess')); // Translated success message
        setError(null);
      } else {
        setMessage(null);
        setError(t('basicInfo.changePasswordError')); // Translated error message
      }
    } catch (error) {
      setMessage(null);
      setError(t('basicInfo.error')); // Translated error message
    }
  };

  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;

  // useEffect(() => {
  //   const loadProfileInfo = async () => {
  //     try {
  //       const data = await fetchBasicInfo(user.email);
  //       setProfileInfo(data);
  //     } catch (error) {
  //       setError(t('basicInfo.fetchProfileError')); // Translated fetch error message
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   loadProfileInfo();
  // }, [t, user.email]);
  useEffect(() => {
    const loadProfileInfo = async () => {
      if (user && user.email) { // Check if user and user.email exist
        try {
          const data = await fetchBasicInfo(user.email);
          setProfileInfo(data);
          console.log(data);
        } catch (error) {
          setError(t('basicInfo.fetchProfileError')); // Translated fetch error message
        } finally {
          setLoading(false);
        }
      } else {
        setError(t('basicInfo.userNotFoundError')); // Custom error for missing user
        setLoading(false);
      }
    };

    loadProfileInfo();
  }, [t, user.full_name]);


  return (
    <div>
      <h3>{t('basicInfo.heading')}</h3> {/* Translated heading */}
      <div className="content">
        {loading ? (
          <Spin tip={t('basicInfo.loadingTip')} /> /* Translated loading tip */
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div>
            <h4>{profileInfo.full_name}</h4>
            <p className="profile-manager">{t('basicInfo.hubManager')}</p> {/* Translated job title */}
            <p className="profile-email">{profileInfo.email}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
