
import React from "react";
import { Pagination as AntPagination } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import './style.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { t } = useTranslation();
  return (
    <div className="pagination-container">
      <div className="pagination">
        <button
          className="prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t('pagination.previous')}
        </button>
        <AntPagination
          current={currentPage}
          total={totalPages * 10} // Assuming 10 items per page
          pageSize={10}
          onChange={(page) => onPageChange(page)}
          showSizeChanger={false}
          showQuickJumper={false}
          hideOnSinglePage
        />
        <button
          className="next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {t('pagination.next')}
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
