// import React, { createContext, useContext, useState } from 'react';

// // Create context
// const PaymentMethodContext = createContext();

// // Provider component
// export const PaymentMethodProvider = ({ children }) => {
// //   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
//   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("nn");

// //   const handleSelectPaymentMethod = (method) => {
// //     setSelectedPaymentMethod(method);
// //   };
// console.log("selectedPaymentMethod***************",selectedPaymentMethod);

//   return (
//     <PaymentMethodContext.Provider value={{ selectedPaymentMethod, setSelectedPaymentMethod }}>
//       {children}
//     </PaymentMethodContext.Provider>
//   );
// };

// // Custom hook to use the context
// export const usePaymentMethod = () => {
//   return useContext(PaymentMethodContext);
// };



import React, { createContext, useContext, useState } from 'react';

// Create context
const PaymentMethodContext = createContext();

// Provider component
export const PaymentMethodProvider = ({ children }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

  // Function to handle payment method change
  const handleSelectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
  };

  console.log("selectedPaymentMethod***************", selectedPaymentMethod);

  return (
    <PaymentMethodContext.Provider value={{ selectedPaymentMethod, setSelectedPaymentMethod, handleSelectPaymentMethod }}>
      {children}
    </PaymentMethodContext.Provider>
  );
};

// Custom hook to use the context
export const usePaymentMethod = () => {
  return useContext(PaymentMethodContext);
};
