// import React from "react";
// import { Drawer } from "antd";
// import { useTranslation } from "react-i18next";

// const Sidebar = ({ categories, onSelectCategory, selectedCategory, isVisible, onClose, isSmallScreen }) => {
//   const { t } = useTranslation(); // Initialize translation hook

//   const sidebarContent = (
//     <div className="sidebar">
//       <ul>
//         {categories.length > 0 ? (
//           categories.map((category) => (
//             <li
//               key={category.item_group}
//               onClick={() => onSelectCategory(category.item_group)}
//               className={selectedCategory === category.item_group ? "active" : ""}
//             >
//               {category.item_group}
//             </li>
//           ))
//         ) : (
//           <li>{t('SideBar.no_categories')}</li> // Translate "No categories available"
//         )}
//       </ul>
//     </div>
//   );
//   // console.log("Categories in sidebar: " + JSON.stringify(categories));
//   //  console.table(categories)
//   return isSmallScreen ? (
//     <Drawer
//       title={t('SideBar.categories')} // Translate "Categories"
//       placement="left"
//       closable={true}
//       onClose={onClose}
//       visible={isVisible}
//       bodyStyle={{ padding: 0 }}
//     >
//       {sidebarContent}
//     </Drawer>
//   ) : (
//     <>{sidebarContent}</>
//   );
// };

// export default Sidebar;


import React from "react";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";

const Sidebar = ({ categories, onSelectCategory, selectedCategory, isVisible, onClose, isSmallScreen }) => {
  const { t } = useTranslation(); // Initialize translation hook

  const sidebarContent = (
    <div className="sidebar" style={{ maxHeight: '80vh', overflowY: 'auto' }}> {/* Set maxHeight and overflowY */}
      <ul>
        {categories.length > 0 ? (
          categories.map((category) => (
            <li
              key={category.item_group}
              onClick={() => onSelectCategory(category.item_group)}
              className={selectedCategory === category.item_group ? "active" : ""}
            >
              {category.item_group}
            </li>
          ))
        ) : (
          <li>{t('SideBar.no_categories')}</li> // Translate "No categories available"
        )}
      </ul>
    </div>
  );

  return isSmallScreen ? (
    <Drawer
      title={t('SideBar.categories')} // Translate "Categories"
      placement="left"
      closable={true}
      onClose={onClose}
      visible={isVisible}
      bodyStyle={{ padding: 0 }}
    >
      {sidebarContent}
    </Drawer>
  ) : (
    <>{sidebarContent}</>
  );
};

export default Sidebar;
