import { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, notification } from "antd";
import { createCustomer, fetchCustomers } from "../modules/LandingPage";
import { SearchCustomerFromList } from "../utils/Searching";
import { useTranslation } from 'react-i18next';


const AddCustomerForm = ({ searchTerm, onAddCustomer }) => {
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setemailError] = useState("");
  const [customers, setCustomers] = useState([]);
  const checkboxRef = useRef(null)
  const { t } = useTranslation();


  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const customerData = await fetchCustomers();
        setCustomers(customerData);
      } catch (err) {
        console.log("Error Occur in AddCustomer", err);
      }
    };
    loadCustomers();
  }, []);

  useEffect(() => {
    const termSearch = searchTerm.trim()
    const isNumeric = /^\d+$/.test(termSearch);
    if (isNumeric) {
      setCustomerNumber(searchTerm);
      setCustomerName("");
    } else {
      setCustomerNumber("");
      setCustomerName(searchTerm);
    }
  }, [searchTerm]);


  const validateInputs = () => {
    let isValid = true;

    if (!customerName.trim()) {
      setNameError(t("addCustomer.nameError"));
      isValid = false;
    } else {
      setNameError("");
    }

    if (!customerNumber.trim()) {
      setNumberError(t("addCustomer.numberErrorInvalid"));
      isValid = false;
    } else if (!/^\d{10}$/.test(customerNumber)) {
      setNumberError(t("addCustomer.numberErrorInvalid"));
      isValid = false;
    } else {
      setNumberError("");
    }


    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())) {
      setemailError(t("addCustomer.emailErrorInvalid"))
      isValid = false;
    } else {
      setemailError("")
    }

    return isValid;
  };

  const handleCustomerNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setCustomerNumber(value);
    }
  };

  // Function to handle adding a new customer
  const handleAddCustomer = async () => {
    if (!validateInputs()) {
      return;
    }

    try {
      const CheckCustomerExist = SearchCustomerFromList(customers, customerNumber, email)
      if (customerNumber && CheckCustomerExist.mobileNumber) {
        if (email && CheckCustomerExist.email) {
          const res = await createCustomer(customerName, customerNumber, email);
          if (res.status === 200) {
            // Modal.success({
            //   title: "Success",
            //   content: (t("addCustomer.successMessage")),
            // });
            notification.success({
              description: (t("addCustomer.successMessage"))
              ,
              duration: 1,
            });
            onAddCustomer(res.data.message.customer);
            setCustomerName("");
            setCustomerNumber("");
            setEmail("");
          } else {
            notification.error({
              message: "Error",
              description: `${t("addCustomer.unexpectedStatusCode")}: ${res.status}`,
            });
          }
        } else if (!email) {
          const res = await createCustomer(customerName, customerNumber, email);
          if (res.status === 200) {
            // Modal.success({
            //   title: "Success",
            //   content: "Customer created successfully.",
            // });
            notification.success({
              description: (t("addCustomer.successMessage")),
              duration: 1,
            });
            onAddCustomer(res.data.message.customer);
            setCustomerName("");
            setCustomerNumber("");
            setEmail("");
          } else {
            notification.error({
              message: "Error",
              description: `Unexpected status code: ${res.status}`,
            });
          }

        } else {
          setemailError(t("addCustomer.emailAlreadyRegistered"))
        }
      } else {
        setNumberError(t("addCustomer.mobileAlreadyRegistered"))
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: `${t("addCustomer.errorMessage")}: ${error.message}`,
      });
    }
  };

  return (
    <div className="autoCompleteForm">
      <span className="no-data-found">{t("addCustomer.noDataFound")}</span>

      <div className="customerNumber">
        <p className="countrycode">{t("addCustomer.countryCode")}</p>
        <Input
          placeholder={t("addCustomer.mobileNumberPlaceholder")}
          value={customerNumber}
          onChange={handleCustomerNumberChange}
          required
        />
      </div>

      {numberError && <span className="error-message">{numberError}</span>}
      <Input
        placeholder={t("addCustomer.customerNamePlaceholder")}
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        required
      />
      {nameError && <span className="error-message">{nameError}</span>}
      <Input
        placeholder={t("addCustomer.emailPlaceholder")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {emailError && <span className="error-message">{emailError}</span>}
      <Button type="primary" onClick={handleAddCustomer}>
        {t("addCustomer.addProceedButton")}
      </Button>
    </div>
  );
};

export default AddCustomerForm;

